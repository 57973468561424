import * as React from "react"

const WCCIntro = () => { 
    return(
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold border-b-4 border-black my-14">Meet the WCC Language Program</h2>
        <p className="mb-6 text-xl">
          WCC Language Program develops a large variety of both digital and physical resources including dictionary 
          apps, flash cards, learning games (digital/physical), online learning platforms and toolkits.
        </p>
        <p className="mb-6 text-xl">
          Recently they have found much success with the internal development of Language Dictionary apps for several communities.
        </p>
        <p className="mb-6 text-xl">
          In addition to team members who are skilled in development, audio and video production they have a fully 
          qualified Linguist with over 40 year's experience on staff to help communities and language groups.
        </p>
        <p className="mb-6 text-xl">
          WCC LP approached Anomaly with a bold vision of using cutting-edge mobile technology like computer vision 
          to build delightful educational experiences.
        </p>

      </div>
    );
};

export default WCCIntro;