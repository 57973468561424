import * as React from "react"
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

const AppSelector = () => {
  return (
    <div className="text-white bg-black">
      <section className="grid grid-cols-1 gap-2 pb-16 mx-4 text-center lg:mx-auto lg:grid-cols-4 lg:max-w-screen-lg">
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-6 text-2xl font-semibold">Yalbilinya</h3>
          <Link className="shadow-2xl shadow-white rounded-3xl"
            to="/portfolio/wcc/">
            <StaticImage
              imgClassName="rounded-3xl"
              height={128}
              width={128}
              src="../../images/icon-yalbilinya.png"
              alt="Logo - Yalbilinya"
              placeholder="dominantColor" />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-6 text-2xl font-semibold">Gurray</h3>
          <Link className="shadow-2xl shadow-white rounded-3xl"
            to="/portfolio/wcc/gurray">
            <StaticImage
              imgClassName="rounded-3xl"
              height={128}
              width={128}
              src="../../images/icon-gurray.png"
              alt="WCC - Gurray"
              placeholder="dominantColor" />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-6 text-2xl font-semibold">Dictionaries</h3>
          <Link className="shadow-2xl shadow-white rounded-3xl"
            to="/portfolio/wcc/dictionary">
            <StaticImage
              imgClassName="rounded-3xl"
              height={128}
              width={128}
              alt="Logo - WCC Dictionaries"
              src="../../images/icon-wcc-dictionary.png"
              placeholder="dominantColor" />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h3 className="mb-6 text-2xl font-semibold opacity-20">Dhaga</h3>
          <Link className="shadow-2xl shadow-white opacity-20 rounded-3xl"
            to="/portfolio/wcc/dhaga">
            <StaticImage
              imgClassName="rounded-3xl"
              height={128}
              width={128}
              alt="Logo - Dhaga"
              src="../../images/icon-dhaga.png"
              placeholder="dominantColor" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default AppSelector;