import React from 'react'

const PortfolioItemSummary = ({expertise, platforms, deliverables, linkText, href}) => {
    return (
        <section className="grid gap-4 py-20 mx-4 text-center grid-col1 lg:max-w-screen-lg lg:mx-auto lg:grid-cols-4">
          <article>
            <h3 className="font-semibold">Expertise</h3>
            <p>{expertise}</p>
          </article>
          <article>
            <h3 className="font-semibold">Platforms</h3>
            <p>{platforms}</p>
          </article>
          <article>
            <h3 className="font-semibold">Deliverables</h3>
            <p>{deliverables}</p>
          </article>
          <article>
            <h3 className="font-semibold">Check it out?</h3>
            <p><a className="underline" href={href}>{linkText}</a></p>
          </article>
        </section>
    );
};

export default PortfolioItemSummary;

