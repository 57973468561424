import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const AppStoreLinks = ({ iOSStoreLink, macOSStoreLink, googlePlayStoreLink }) => {
    const data = useStaticQuery(graphql`
        query {
            LogoiOSAppStore: file(relativePath: { eq: "badge-appstore.svg" }) {
                publicURL
              },
              LogoMacAppStore: file(relativePath: { eq: "badge-mac-appstore.svg" }) {
                publicURL
              },
              LogoGooglePlayStore: file(relativePath: { eq: "badge-google-play.svg" }) {
                publicURL
              },
        }
    `);

    return (
        <div className="py-16 bg-white">
            <p className="mb-16 text-2xl text-center text-gray-500">Available on these platforms.</p>
            <section className="flex flex-col items-center justify-center mx-auto lg:flex-row lg:max-w-screen-sm">
                {macOSStoreLink && (
                <a href={macOSStoreLink}>
                    <img className="h-12 mb-4 lg:mb-0" src={data.LogoMacAppStore.publicURL} alt="Mac AppStore Logo"/>
                </a>)}
                {iOSStoreLink && (
                <a href={iOSStoreLink}>
                    <img className="h-12 mb-4 lg:ml-4 lg:mb-0" src={data.LogoiOSAppStore.publicURL} alt="iOS AppStore Logo"/>
                </a>)}
                {googlePlayStoreLink && (
                <a href={googlePlayStoreLink}>
                    <img className="h-12 lg:ml-4" src={data.LogoGooglePlayStore.publicURL} alt="Google Play Logo"/>
                </a>)}
            </section>
        </div>
    );
};

export default AppStoreLinks;
