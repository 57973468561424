import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PortfolioItemSummary from "../PortfolioItemSummary"

const IntroTitle = () => {
  return (
    <div className="flex flex-col items-center justify-center mx-auto text-center text-white bg-black">
      <StaticImage
        placeholder="dominantColor"
        height={100}
        width={100}
        src="../../images/logo-wcclp-inverted.png"
        alt="WCC LP logo mark" />
      <h1 className="mt-8 text-4xl font-semibold">WCC Language Program</h1>
      <p className="mt-2 text-2xl text-center">At the crossroads of culture and technology.</p>
      <PortfolioItemSummary
        expertise="Design and Development"
        platforms="iOS, Android, Web"
        deliverables="iOS, Android apps, APIs"
        href="https://www.wcclp.com.au"
        linkText="Info pages" />
    </div>

  );
};

export default IntroTitle;