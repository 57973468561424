import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import IntroTitle from "../../../components/wcc/IntroTitle"
import AppSelector from "../../../components/wcc/AppSelector"
import WCCIntro from "../../../components/wcc/WCCIntro"
import AppStoreLinks from "../../../components/AppStoreLinks"

const Yalbilinya = () => {

  return (
    <Layout bgClass="bg-black" fgMode="light">
      <Seo 
        title="Yalbilinya"
        description=""
      />
      <IntroTitle />
      <AppSelector />
      <div className="w-full pt-8 pb-16 text-white bg-black">
        <div className="grid max-w-screen-lg grid-cols-[1fr,1.2fr,1fr] gap-4 mx-auto">
          <div className="grid pt-16 pl-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">A vision for education</h2>
              <p>
                Yalbilinya uses computer Vision technology built into modern phones to highlight areas of the user's face.
                The user is able to tap parts of the face to hear the words in the chosen language.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Custom algorithms</h2>
              <p>
                Parts of the face (like hair and chin) aren't mapped by the facial recognition system,
                we set out to build our own extensions so we could bring the desired experience.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Towards ARKit</h2>
              <p>
                As support for augmented reality extend across devices we plan to map even more
                objects and extend the experience beyond just the face.
              </p>
            </section>

          </div>
          <div className="flex items-center justify-center">
            <StaticImage
              height={730}
              width={363}
              alt="Yalbilinya Landing"
              src="../../../images/iphone-wcc-yalbilinya.png" 
              placeholder="blurred"/>
          </div>
          <div className="grid pt-16 pr-6">
            <section>
              <h2 className="mb-4 text-2xl font-bold">Game like experiences</h2>
              <p>
                Use the quiz function to challenge your knowledge of each language.
                The app announces a word and the user has to touch the part of the face that matches the word.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Feature rich</h2>
              <p>
                Toggle English text, audio, or the outlines. Mirror left and right
                or simplify your experience to ignore them.
              </p>
            </section>
            <section>
              <h2 className="mb-4 text-2xl font-bold">Foundational technology</h2>
              <p>
                The technology behind Yalbilinya is extensible and can support
                any other languages that wish to make use of it.
              </p>
            </section>

          </div>
        </div>
      </div>
      <AppStoreLinks 
      iOSStoreLink="https://bit.ly/3kxWv6V"
      googlePlayStoreLink="https://bit.ly/3QSe4wa"/>
      <WCCIntro/>
    </Layout>
 );
};
export default Yalbilinya
